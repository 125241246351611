/* eslint-disable */
import { analytics } from 'services';
import { chunkArray } from 'utils';
import asyncPool from 'utils/async-pool';
import ApiService from './_ApiService';

class Files extends ApiService {
  community = null;

  FILES_UPLOAD_URL =
    window.pickit.config.BASE_URL === 'pickit.com'
      ? window.pickit.config.FILES_UPLOAD_URL
      : window.pickit.config.MEDIA_URL;

  type = null;

  constructor(type) {
    super();
    this.type = type;
  }

  async invalidateJWT() {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
    };
    return this.request(`/invalidate-jwt`, options);
  }

  async getData() {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
    };
    return this.request(`/${this.type}/data`, options).then(
      this.handleJSONResponse
    );
  }

  async getSasTokens() {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
    };
    return this.request(`/${this.type}/tokens`, options).then(
      this.handleJSONResponse
    );
  }

  async getSasToken(community = '') {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
    };
    return this.request(
      `/${this.type}/token/${community ? `?community=${community}` : ''}`,
      options
    ).then(this.handleJSONResponse);
  }

  async search(params) {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
    };
    const req = await this.request(
      `/${this.type}/search?${new URLSearchParams(params).toString()}`,
      options
    );
    const data = await req.json();
    return data;
  }

  async getAttributes() {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
    };
    const req = await this.request(`/${this.type}/attributes`, options);
    const data = await req.json();
    return data;
  }

  async getAttributeEstimation() {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
    };
    const req = await this.request(
      `/${this.type}/attributes/estimation`,
      options
    );
    const data = await req.json();
    return data;
  }

  async getFile(type, file) {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
    };
    const req = await this.request(
      `/${this.type}/file/${type}/${file}`,
      options
    );
    const data = await req.json();
    return data.file;
  }

  async getExplore() {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
    };
    const req = await this.request(`/${this.type}/explore`, options);
    const data = await req.json();
    return data;
  }

  async getCollection(id) {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
    };
    return this.request(`/${this.type}/collection/${id}`, options).then(
      this.handleJSONResponse
    );
  }

  async getBoardStats(startDate, endDate) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      body: JSON.stringify({
        startDate,
        endDate,
      }),
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
    };

    const res = await this.request(`/community/stats/boards`, options);
    const data = await res.json();
    return data;
  }

  async getCloudStorage(){
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
    };

    const res = await this.request(`/user/cloud-storage`, options);
    const data = await res.json();
    return data;
  }

  async saveCloudStorage(data){
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      body: JSON.stringify({
        content: data
      }),
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
    };

    const res = await this.request(`/user/cloud-storage`, options, data);
    const resData = await res.json();
    return resData;
  }

  async getAssetStatsByType(id, time) {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
    };

    const res = await this.request(
      `/community/asset-stats/file/${id}/${time}`,
      options
    );
    const data = await res.json();
    return data;
  }

  async getAllFilesInCollection(id) {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
    };
    return this.request(`/${this.type}/collection/${id}/files`, options).then(
      this.handleJSONResponse
    );
  }

  async mergeFaces(fromId, toId) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
      body: JSON.stringify({
        fromId,
        toId,
      }),
    };
    const res = await this.request(`/community/faces/merge`, options);
    const data = await res.json();
    return data;
  }

  async saveFace({ name, _id }) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
      body: JSON.stringify({
        _id,
        name,
      }),
    };
    const res = await this.request(`/community/faces/face`, options);
    const data = await res.json();
    return data;
  }

  async getG2Feedback() {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
    };
    const res = await this.request(`/getG2Feedback`, options);
    const data = await res.json();
    return data;
  }

  async getUsage(fileId, period = "7d"){
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
    };
    const res = await this.request(`/${this.type}/file/usage/${fileId}?period=${period}`, options);
    const data = await res.json();
    return data;
  }

  async getFaces(page_nr = 1) {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
    };
    const res = await this.request(`/community/faces?page=${page_nr}`, options);
    const data = await res.json();
    return data;
  }

  async saveSettingsPartial(settings) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
      body: JSON.stringify({ settings }),
    };
    const res = await this.request(`/community/settings/partial`, options);
    const data = await res.json();
    this.clearCache(); // Profile settings are cached in BE. This forces it to reset
    return data;
  }

  async saveSettings(settings) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
      body: JSON.stringify({ settings }),
    };
    const res = await this.request(`/community/settings`, options);
    const data = await res.json();
    this.clearCache(); // Profile settings are cached in BE. This forces it to reset
    return data;
  }

  async getFilters(prefs) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
      body: JSON.stringify(prefs || {}),
    };
    return this.request(`/${this.type}/files/filters`, options).then(
      this.handleJSONResponse
    );
  }

  async getDocument(id) {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
    };
    return this.request(`/${this.type}/document/${id}`, options).then(
      this.handleJSONResponse
    );
  }

  async saveGroup(groupData) {
    const id = groupData._id || 'create';
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
      body: JSON.stringify(groupData),
    };
    return this.request(`/${this.type}/group/${id}`, options).then(
      this.handleJSONResponse
    );
  }

  async deleteGroup(id) {
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
    };
    return this.request(`/${this.type}/group/${id}`, options);
  }

  async deleteCollection(id) {
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
    };
    return this.request(`/${this.type}/collection/${id}`, options);
  }

  async saveCollection(collectionData) {
    const id = collectionData._id || 'create';
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
      body: JSON.stringify(collectionData),
    };
    return this.request(`/${this.type}/collection/${id}`, options).then(
      this.handleJSONResponse
    );
  }

  async sortGroups(data) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
      body: JSON.stringify(data),
    };
    return this.request(`/${this.type}/groups/sort`, options).then(
      this.handleJSONResponse
    );
  }

  async sortCollections(data) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
      body: JSON.stringify(data),
    };
    return this.request(`/${this.type}/collections/sort`, options).then(
      this.handleJSONResponse
    );
  }

  async savePartial(fileData, advancedFind) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      body: JSON.stringify({
        update: fileData,
        advancedFind,
      }),
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
    };
    return this.request(`/${this.type}/file/save-partial`, options).then(
      this.handleJSONResponse
    );
  }

  async saveDocument(documentData, parameters) {
    const id = documentData._id || 'create';
    const query = new URLSearchParams(parameters);
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl:
        id !== 'create'
          ? window.pickit.config.MEDIA_URL
          : this.FILES_UPLOAD_URL,
      body: JSON.stringify(documentData),
    };
    return this.request(
      `/${this.type}/document/${id}?${query.toString()}`,
      options
    ).then(this.handleJSONResponse);
  }

  async getOCRContextForFile(documentId, fullText = false) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
      body: JSON.stringify({
        fullText,
      }),
    };
    return this.request(
      `/${this.type}/document/ocr-context/${documentId}`,
      options
    ).then(this.handleJSONResponse);
  }

  async updateOCRContextStatusForFile(documentId, disable = false) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
      body: JSON.stringify({
        disable,
      }),
    };
    return this.request(
      `/${this.type}/document/ocr-context-status/${documentId}`,
      options
    ).then(this.handleJSONResponse);
  }

  async parseFile(id, preferences = {}) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: this.FILES_UPLOAD_URL,
      body: JSON.stringify(preferences),
    };
    return this.request(`/${this.type}/document/parse/${id}`, options).then(
      this.handleJSONResponse
    );
  }

  async saveDocuments(documentIds, data) {
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
      body: JSON.stringify({
        documents: documentIds,
        data,
      }),
    };
    return this.request(`/${this.type}/documents/update`, options).then(
      this.handleJSONResponse
    );
  }

  async saveDocumentsWhere(where, set) {
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
      body: JSON.stringify({
        where,
        set,
      }),
    };
    return this.request(`/${this.type}/documents/update/where`, options).then(
      this.handleJSONResponse
    );
  }

  async deleteDocument(document) {
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
    };
    return this.request(`/${this.type}/document/${document._id}`, options);
  }

  async deleteDocuments(documents) {
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      body: JSON.stringify({
        files: documents,
      }),
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
    };
    return this.request(`/${this.type}/documents/delete`, options);
  }

  async saveDocumentFeedback(document, collection, data) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
      body: JSON.stringify({
        feedback: data,
        collectionId: collection._id,
      }),
    };
    return this.request(
      `/${this.type}/file/feedback/${document._id}`,
      options
    ).then(this.handleJSONResponse);
  }

  async saveDocumentFeedbackViewedStatus(documents, viewed) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
      body: JSON.stringify({
        documents,
        viewed,
      }),
    };
    return this.request(`/${this.type}/file/feedback/viewed`, options).then(
      this.handleJSONResponse
    );
  }

  async removeDocumentFeedback(documents, userId) {
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
      body: JSON.stringify({
        documents,
        userId,
      }),
    };
    return this.request(`/${this.type}/file/feedback`, options).then(
      this.handleJSONResponse
    );
  }

  async getCollections(query) {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
    };
    query = new URLSearchParams(query);
    return this.request(
      `/${this.type}/collections?${query.toString()}`,
      options
    ).then(this.handleJSONResponse);
  }

  async getDocuments(query, config = {}) {
    if (typeof query?.filter === 'object') {
      query.filter = JSON.stringify(query.filter);
    }
    const options = {
      method: config.asPOST ? 'POST' : 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': config?.communitySlug || this.communitySlug,
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
      body: config.asPOST ? JSON.stringify(query) : null,
    };
    let url = `/${this.type}/documents`;
    if (!config.asPOST) {
      query = new URLSearchParams(query);
      url += `?${query.toString()}`;
    }
    return this.request(url, options).then(this.handleJSONResponse);
  }

  async getDocumentsFromArrayId(data, prefs) {
    const params = new URLSearchParams(prefs);
    const perChunk = 200; // items per chunk
    const chunkedData =
      data?.reduce?.((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / perChunk);
        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []; // start a new chunk
        }
        resultArray[chunkIndex].push(item);
        return resultArray;
      }, []) || [];
    let results = [];
    try {
      analytics.trackEvent('getDocumentsFromArrayId', {
        'Documents From Array Id Files': `${data.length.toString()} files`,
        'Documents From Array Id Chunks': `${chunkedData.length.toString()} chunks`,
        'Documents From Array Id Community': this.communitySlug,
      });
    } catch (e) {}
    await asyncPool(1, chunkedData, async (data) => {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
        body: JSON.stringify({ documents: data }),
      };
      const result = await this.request(
        `/${this.type}/documents/from-array?${params.toString()}`,
        options
      ).then(this.handleJSONResponse);
      results = [...results, ...result];
    });
    return results;
  }

  async importCheckReferences(references, from, checksums) {
    const perChunk = 500; // items per chunk
    const chunkedReferences =
      references?.reduce?.((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / perChunk);
        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []; // start a new chunk
        }
        resultArray[chunkIndex].push(item);
        return resultArray;
      }, []) || [];
    try {
      analytics.trackEvent('getImportCheckReferences', {
        'Documents From Array Id Files': `${references.length.toString()} files`,
        'Documents From Array Id Chunks': `${chunkedReferences.length.toString()} chunks`,
        'Documents From Array Id Community': this.communitySlug,
      });
    } catch (e) {}
    let results = [];
    await asyncPool(1, chunkedReferences, async (references) => {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
        body: JSON.stringify({ from, references, checksums }),
      };
      const data = await this.request(
        `/${this.type}/import/check-references`,
        options
      ).then(this.handleJSONResponse);
      results = [...results, ...data];
    });
    return results;
  }

  async importGetFiles(from) {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
    };
    return this.request(`/${this.type}/import/get-files/${from}`, options).then(
      this.handleJSONResponse
    );
  }

  async getDocumentByFileId(fileId) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
    };
    return this.request(
      `/${this.type}/documents/from-file/${fileId}`,
      options
    ).then(this.handleJSONResponse);
  }

  async getCollectionsFromArrayId(data) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
      body: JSON.stringify({ collections: data }),
    };
    return this.request(`/${this.type}/collections/from-array`, options).then(
      this.handleJSONResponse
    );
  }

  async getFileBase64(fileId) {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
    };
    return this.request(
      `/${this.type}/document/download/${fileId}?community=${this.communitySlug}&base64=1`,
      options
    ).then(this.handleTextResponse);
  }

  async importMedia(url, metadata, params) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
      body: JSON.stringify({ url, metadata, preferences: params }),
    };
    return this.request(`/${this.type}/import`, options).then(
      this.handleJSONResponse
    );
  }

  async inspectionToolStatus() {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: this.FILES_UPLOAD_URL,
    };
    return this.request(`/inspection-tool/status`, options).then(
      this.handleJSONResponse
    );
  }

  async inspectionToolExtracted(fileId) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: this.FILES_UPLOAD_URL,
    };
    return this.request(`/inspection-tool/extracted/${fileId}`, options).then(
      this.handleJSONResponse
    );
  }

  async inspectionToolSocket(
    file,
    postfix,
    selectedFiles,
    collectFiles = false,
    prefs = {},
    params = {}
  ) {
    return new Promise((_resolve, reject) => {
      const socket = new WebSocket(
        `${
          this.FILES_UPLOAD_URL.includes('https') ? 'wss' : 'ws'
        }://${this.FILES_UPLOAD_URL.split(
          '//'
        ).pop()}/inspection-tool${postfix}`
      );
      socket.onopen = () => {
        socket.send(
          JSON.stringify({
            auth: this.defaultHeaders.Authorization,
            community: this.communitySlug,
            data: {
              id: file?._id,
              selectedFiles,
              collectFiles,
              prefs,
              params,
            },
          })
        );
      };
      if (prefs.onProcess) {
        socket.addEventListener('message', (event) => {
          const message = JSON.parse(event.data);
          prefs.onProcess(message);
        });
      }
      if (prefs.onClose) {
        socket.addEventListener('close', () => {
          prefs.onClose();
        });
      }
      socket.addEventListener('error', () => {
        reject();
      });
      return socket;
    });
  }

  async importFileSocket(type, url, metadata, prefs = {}, params = {}) {
    return new Promise((resolve, reject) => {
      const socket = new WebSocket(
        `${
          this.FILES_UPLOAD_URL.includes('https') ? 'wss' : 'ws'
        }://${this.FILES_UPLOAD_URL.split('//').pop()}/${type}/import/socket`
      );
      socket.onopen = () => {
        socket.send(
          JSON.stringify({
            auth: this.defaultHeaders.Authorization,
            community: this.communitySlug,
            data: {
              url,
              metadata,
              preferences: params,
            },
          })
        );
      };
      if (prefs.onProcess) {
        socket.addEventListener('message', (event) => {
          const message = JSON.parse(event.data);
          if (message.code === 'IMPORTED' || message.code === 'FILE_IMPORTED') {
            resolve(message.file);
          }
          prefs.onProcess(message);
        });
      }
      if (prefs.onClose) {
        socket.addEventListener('close', () => {
          prefs.onClose();
        });
      }
      socket.addEventListener('error', () => {
        reject();
      });
      return socket;
    });
  }

  async trainFaceStatus() {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
    };
    return this.request(`/${this.type}/attributes/face/train`, options).then(
      this.handleJSONResponse
    );
  }

  async getFaceStatus() {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
    };
    return this.request(`/${this.type}/attributes/face/status`, options).then(
      this.handleJSONResponse
    );
  }

  faceScanner(prefs = {}) {
    const socket = new WebSocket(
      `${
        window.pickit.config.MEDIA_URL.includes('https') ? 'wss' : 'ws'
      }://${window.pickit.config.MEDIA_URL.split('//').pop()}/${
        this.type
      }/attributes/face/scanner`
    );
    socket.onopen = () => {
      setTimeout(() => {
        socket.send(
          JSON.stringify({
            auth: this.defaultHeaders.Authorization,
            community: this.communitySlug,
          })
        );
      }, 200);
    };
    socket.addEventListener('message', (event) => {
      const message = JSON.parse(event.data);
      prefs.onProcess(message);
    });

    return socket;
  }

  attributeScanner(prefs = {}) {
    const socket = new WebSocket(
      `${
        window.pickit.config.FILES_UPLOAD_URL.includes('https') ? 'wss' : 'ws'
      }://${window.pickit.config.FILES_UPLOAD_URL.split('//').pop()}/${
        this.type
      }/attributes/scanner`
    );
    socket.onopen = () => {
      setTimeout(() => {
        socket.send(
          JSON.stringify({
            auth: this.defaultHeaders.Authorization,
            community: this.communitySlug,
          })
        );
      }, 200);
    };
    socket.addEventListener('message', (event) => {
      const message = JSON.parse(event.data);
      prefs.onProcess(message);
    });

    return socket;
  }

  async exportAsPptx(fileIds) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      body: JSON.stringify({
        fileIds,
      }),
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
    };
    return this.request(`/${this.type}/export-as-pptx`, options);
  }

  async generate(fileId, type, prefs = {}) {
    const socket = new WebSocket(
      `${
        this.FILES_UPLOAD_URL.includes('https') ? 'wss' : 'ws'
      }://${this.FILES_UPLOAD_URL.split('//').pop()}/${
        this.type
      }/file/${fileId}/generate/${type}`
    );
    socket.onclose = () => {
      prefs?.onClose?.();
    };
    socket.onopen = () => {
      setTimeout(() => {
        socket.send(
          JSON.stringify({
            auth: this.defaultHeaders.Authorization,
            community: this.communitySlug,
            data: prefs?.data,
          })
        );
      }, 200);
    };
    socket.addEventListener('message', (event) => {
      const message = JSON.parse(event.data);
      prefs.onProgress?.(message);
    });

    return socket;
  }

  async analyzeFiles(type, prefs = {}) {
    const socket = new WebSocket(
      `${
        window.pickit.config.MEDIA_URL.includes('https') ? 'wss' : 'ws'
      }://${window.pickit.config.MEDIA_URL.split(
        '//'
      ).pop()}/${type}/analyze/files`
    );
    socket.onopen = () => {
      socket.send(
        JSON.stringify({
          auth: this.defaultHeaders.Authorization,
          community: this.communitySlug,
        })
      );
    };
    // if (prefs.onProcess) {
    socket.addEventListener('message', (event) => {
      const message = JSON.parse(event.data);
      prefs.onProcess(message);
    });
  }

  async init(clearCache, forceCommunitySlug) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': forceCommunitySlug || this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
    };
    return this.request(
      `/community/init${clearCache ? '?skip_cache=1' : ''}`,
      options
    ).then(this.handleJSONResponse);
  }

  async getTokens() {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
    };
    return this.request(`/community/tokens`, options).then(
      this.handleJSONResponse
    );
  }

  async clearCache() {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
    };
    return this.request(`/clear-cache`, options);
  }

  async replaceMedia(url, id, checksum, params = {}) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.MEDIA_URL,
      body: JSON.stringify({ url, id, checksum, ...params }),
    };
    return this.request(`/${this.type}/import/replace`, options).then(
      this.handleJSONResponse
    );
  }

  templates = {
    list: async () => {
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      return this.request(`/${this.type}/templates`, options).then(
        this.handleJSONResponse
      );
    },
    create: async (templateSlug) => {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      return this.request(
        `/${this.type}/templates/${templateSlug}`,
        options
      ).then(this.handleJSONResponse);
    },
  };

  hubspot = {
    uploadStatus: async (url, auth) => {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'pickit-api-key': window.pickit.keys.PICKIT_API,
        },
        requestUrl: window.pickit.config.MEDIA_URL,
        body: JSON.stringify({
          url,
          auth,
        }),
      };

      return this.request(`/hubspot/upload-status`, options).then(
        this.handleJSONResponse
      );
    },
    proxy: async (reqObj) => {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'pickit-api-key': window.pickit.keys.PICKIT_API,
        },
        requestUrl: window.pickit.config.MEDIA_URL,
        body: JSON.stringify(reqObj),
      };

      return this.request(`/hubspot/proxy`, options);
    },
    getToken: async (code, redirect_uri) => {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
        body: JSON.stringify({
          code,
          redirect_uri,
        }),
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      return this.request(`/hubspot/token/`, options).then(
        this.handleJSONResponse
      );
    },
    refreshToken: async (refresh_token, redirect_uri) => {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
        body: JSON.stringify({
          refresh_token,
          redirect_uri,
        }),
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      return this.request(`/hubspot/token/refresh`, options)
    },
  };

  dropbox = {
    getToken: async (code, redirect_uri) => {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
        body: JSON.stringify({
          code,
          redirect_uri,
        }),
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      return this.request(`/dropbox/token/`, options).then(
        this.handleJSONResponse
      );
    },
    refreshToken: async (refresh_token, scope, redirect_uri) => {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
        body: JSON.stringify({
          refresh_token,
          scope,
          redirect_uri,
        }),
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      return this.request(`/dropbox/token/refresh`, options).then(
        this.handleJSONResponse
      );
    },
  };

  microsoft = {
    getToken: async (code, scope, redirect_uri) => {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
        body: JSON.stringify({
          code,
          scope,
          redirect_uri,
        }),
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      return this.request(`/microsoft/token/`, options).then(
        this.handleJSONResponse
      );
    },
    refreshToken: async (refresh_token, scope, redirect_uri) => {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
        body: JSON.stringify({
          refresh_token,
          scope,
          redirect_uri,
        }),
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      return this.request(`/microsoft/token/refresh`, options).then(
        this.handleJSONResponse
      );
    },
  };
  adobe = {
    getToken: async (code) => {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
        body: JSON.stringify({
          code,
        }),
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      return this.request(`/adobe/token/`, options).then(
        this.handleJSONResponse
      );
    },
    refreshToken: async (refresh_token) => {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
        body: JSON.stringify({
          refresh_token,
        }),
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      return this.request(`/adobe/token/refresh`, options).then(
        this.handleJSONResponse
      );
    },
  };
  box = {
    getToken: async (code) => {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
        body: JSON.stringify({
          code,
        }),
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      return this.request(`/box/token/`, options).then(this.handleJSONResponse);
    },
    refreshToken: async (refresh_token) => {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
        body: JSON.stringify({
          refresh_token,
        }),
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      return this.request(`/box/token/refresh`, options).then(
        this.handleJSONResponse
      );
    },
    revokeToken: async (token) => {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
        body: JSON.stringify({
          token,
        }),
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      return this.request(`/box/token/revoke`, options).then(
        this.handleJSONResponse
      );
    },
  };

  importJobs = {
    getJob: async (id) => {
      const options = {
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      const req = await this.request(`/import/jobs/job/${id}`, options);
      if (!req.ok) {
        return false;
      }
      const data = await req.json();
      return data;
    },
    getJobs: async (type) => {
      const options = {
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      return this.request(`/import/${type}/jobs`, options).then(
        this.handleJSONResponse
      );
    },
    getJobCache: async (type, jobId) => {
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      return this.request(
        `/import/${type}/jobs/job/${jobId}/cache`,
        options
      ).then(this.handleJSONResponse);
    },
    saveJob: async (type, data) => {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
        body: JSON.stringify({
          ...data,
          ...(data?._cache
            ? {
                _createCache: data?._cache,
              }
            : {}),
          implementationVersion: data._id ? data.implementationVersion ?? 1 : 2,
        }),
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      return this.request(
        `/import/${type}/jobs/job/${data._id || 'create'}`,
        options
      ).then(this.handleJSONResponse);
    },
    deleteJob: async (jobId, prefs) => {
      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
        body: JSON.stringify(prefs),
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      return this.request(`/import/jobs/job/${jobId}`, options).then(
        this.handleJSONResponse
      );
    },
  };

  stats = {
    getCommunityEventByUser: async (eventName, time) => {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
        body: JSON.stringify({
          eventName,
          time,
        }),
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      return this.request(
        `/${this.type}/statistics/events-by-users`,
        options
      ).then(this.handleJSONResponse);
    },
    getUserStats: async (userId, time) => {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
        body: JSON.stringify({
          userId,
          time,
        }),
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      return this.request(
        `/${this.type}/statistics/user-activity`,
        options
      ).then(this.handleJSONResponse);
    },
    search: async (params) => {
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      params = new URLSearchParams(params);

      return this.request(
        `/${this.type}/statistics/search?${params.toString()}`,
        options
      ).then(this.handleJSONResponse);
    },
    list: async (params) => {
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      params = new URLSearchParams(params);
      return this.request(
        `/${this.type}/statistics/list?${params.toString()}`,
        options
      ).then(this.handleJSONResponse);
    },
  };

  export = {
    getJob: async (type, id) => {
      const options = {
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      const req = await this.request(`/microsoft/${type}/job/${id}`, options);
      if (!req.ok) {
        return false;
      }
      const data = await req.json();
      return data;
    },
    getJobs: async (type) => {
      const options = {
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      return this.request(`/microsoft/${type}/jobs`, options).then(
        this.handleJSONResponse
      );
    },
    saveJob: async (type, data) => {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
        body: JSON.stringify({
          ...data,
        }),
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      return this.request(
        `/microsoft/${type}/job/${data._id || 'create'}`,
        options
      ).then(this.handleJSONResponse);
    },
    deleteJob: async (type, jobId, prefs) => {
      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
        body: JSON.stringify(prefs),
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      return this.request(`/microsoft/${type}/job/${jobId}`, options).then(
        this.handleJSONResponse
      );
    },
  };

  assets = {
    exportAsPptx: async (folderId) => {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      return this.request(`/assets/item/${folderId}/export-as-pptx`, options);
    },
    getAllItemsByImportJobId: async (jobId) => {
      const options = {
        method: 'GET',
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
      };
      const res = await this.request(
        `/assets/items/by-import-job/${jobId}`,
        options
      ).then(this.handleJSONResponse);
      return res;
    },
    getItemsByIds: async (ids) => {
      const results = [];
      const chunks = chunkArray(ids, 200);
      try {
        analytics.trackEvent('getItemsByIds', {
          'Documents From Array Id Files': `${ids.length.toString()} files`,
          'Documents From Array Id Chunks': `${chunks.length.toString()} chunks`,
          'Documents From Array Id Community': this.communitySlug,
        });
      } catch (e) {}
      await asyncPool(1, chunks, async (itemIds) => {
        const options = {
          method: 'POST',
          skipBaseUrl: true,
          requestUrl: window.pickit.config.MEDIA_URL,
          headers: {
            'Content-Type': 'application/json',
            'pickit-community': this.communitySlug,
            Pragma: 'no-cache',
          },
          body: JSON.stringify({
            ids: itemIds,
          }),
        };
        const res = await this.request(
          `/assets/items/by-array-ids`,
          options
        ).then(this.handleJSONResponse);
        results.push(...res.items);
      });
      return results;
    },
    getItemsByImportReference: async (from, references, checksums) => {
      const results = [];
      const chunkedReferences = chunkArray(references, 100);
      const chunkedChecksums = chunkArray(checksums, 100);
      try {
        analytics.trackEvent('getItemsByImportReference', {
          'Documents From Array Id Files': `${from.length.toString()} files`,
          'Documents From Array Id Chunks': `${chunkedReferences.length.toString()} chunks`,
          'Documents From Array Id Community': this.communitySlug,
        });
      } catch (e) {}
      await asyncPool(1, chunkedReferences, async (references) => {
        const options = {
          method: 'POST',
          skipBaseUrl: true,
          requestUrl: window.pickit.config.MEDIA_URL,
          headers: {
            'Content-Type': 'application/json',
            'pickit-community': this.communitySlug,
            Pragma: 'no-cache',
          },
          body: JSON.stringify({
            from,
            references,
          }),
        };
        const response = await this.request(
          `/assets/items/by-import-references`,
          options
        ).then(this.handleJSONResponse);
        results.push(...response.data);
      });

      await asyncPool(1, chunkedChecksums, async (checksums) => {
        const options = {
          method: 'POST',
          skipBaseUrl: true,
          requestUrl: window.pickit.config.MEDIA_URL,
          headers: {
            'Content-Type': 'application/json',
            'pickit-community': this.communitySlug,
            Pragma: 'no-cache',
          },
          body: JSON.stringify({
            from,
            checksums,
          }),
        };
        const response = await this.request(
          `/assets/items/by-import-references`,
          options
        ).then(this.handleJSONResponse);
        results.push(...response.data);
      });

      return results;
    },
    getItems: async (
      prefs = {
        page: 1,
        limit: 50,
        query: null,
        folderId: null,
      }
    ) => {
      let params = '';
      if (Object.keys(prefs || {})?.length) {
        params = new URLSearchParams(prefs).toString();
      }
      const options = {
        method: 'GET',
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
      };
      return this.request(
        `/assets/items${params ? `?${params.toString()}` : ''}`,
        options
      ).then(this.handleJSONResponse);
    },
    getFolderItems: async (
      folderId,
      prefs = {
        page: 1,
        limit: 50,
        query: null,
      }
    ) => {
      let params = '';
      if (Object.keys(prefs || {})?.length) {
        params = new URLSearchParams(prefs).toString();
      }
      const options = {
        method: 'GET',
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
      };
      return this.request(
        `/asset/${folderId}/items${params ? `?${params.toString()}` : ''}`,
        options
      ).then(this.handleJSONResponse);
    },
    load: async (id, preferences) => {
      let params = '';
      if (Object.keys(preferences || {})?.length) {
        params = new URLSearchParams(preferences).toString();
      }
      const options = {
        method: 'GET',
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
      };
      return this.request(
        `/assets/load/${id}${params ? `?${params.toString()}` : ''}`,
        options
      ).then(this.handleJSONResponse);
    },
    getFolderItem: async (id) => {
      const options = {
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
      };
      return this.request(`/assets/item/${id}`, options).then(
        this.handleJSONResponse
      );
    },
    saveFolderItemsOrder: async (items) => {
      const options = {
        method: 'POST',
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
        body: JSON.stringify({
          items,
        }),
      };
      return this.request(`/assets/item/save-order`, options).then(
        this.handleJSONResponse
      );
    },
    saveFolderItemMultiple: async (items, set) => {
      const results = [];
      const conflictItems = [];
      const chunks = chunkArray(items, 100);
      try {
        analytics.trackEvent('saveFolderItemMultiple', {
          'Documents From Array Id Files': `${items.length.toString()} files`,
          'Documents From Array Id Chunks': `${chunks.length.toString()} chunks`,
          'Documents From Array Id Community': this.communitySlug,
        });
      } catch (e) {}
      await asyncPool(1, chunks, async (i) => {
        const options = {
          method: 'POST',
          skipBaseUrl: true,
          requestUrl: window.pickit.config.MEDIA_URL,
          headers: {
            'Content-Type': 'application/json',
            'pickit-community': this.communitySlug,
            Pragma: 'no-cache',
          },
          body: JSON.stringify({
            items: i,
            set,
          }),
        };
        const res = await this.request(
          `/assets/item/save-multiple`,
          options
        ).then(this.handleJSONResponse);
        results.push(...res.items);
        if (res.conflictItems) {
          conflictItems.push(...res.conflictItems);
        }
      });
      return { items: results, conflictItems };
    },
    deleteMultipleItems: async (items, preferences) => {
      const options = {
        method: 'DELETE',
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
        body: JSON.stringify({
          items,
          preferences,
        }),
      };
      return this.request(`/assets/item/delete-multiple`, options).then(
        this.handleJSONResponse
      );
    },
    saveFolderItem: async (data) => {
      const options = {
        method: 'POST',
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
        body: JSON.stringify({
          data,
        }),
      };
      return this.request(
        `/assets/item/save/${data._id || 'create'}`,
        options
      ).then(this.handleJSONResponse);
    },
    getFoldersByArrayIds: async (ids) => {
      const options = {
        method: 'POST',
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
        body: JSON.stringify({
          ids,
        }),
      };
      return this.request(`/assets/by-array`, options).then(
        this.handleJSONResponse
      );
    },
    deleteFolder: async (id, prefs) => {
      const options = {
        method: 'DELETE',
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
        body: JSON.stringify({
          preferences: prefs,
        }),
      };
      return this.request(`/asset/${id}`, options).then(
        this.handleJSONResponse
      );
    },
    getFoldersByQuery: async (type, query) => {
      const options = {
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
      };
      return this.request(`/assets/${type}/search/${query}`, options).then(
        this.handleJSONResponse
      );
    },
    getFolders: async () => {
      const options = {
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
      };
      return this.request(`/assets`, options).then(this.handleJSONResponse);
    },
    getFolderById: async (parentId) => {
      const options = {
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
      };
      return this.request(`/asset/${parentId}`, options).then(
        this.handleJSONResponse
      );
    },
    getFolderItemsFlat: async (folderId) => {
      const options = {
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
      };
      return this.request(`/asset/${folderId}/flat`, options).then(
        this.handleJSONResponse
      );
    },
    getFoldersByParent: async (parentId) => {
      const options = {
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
      };
      return this.request(`/asset/${parentId}/folders`, options).then(
        this.handleJSONResponse
      );
    },
    saveFolder: async (data) => {
      const id = data._id || 'create';
      delete data._id;
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
        },
        body: JSON.stringify({
          ...data,
        }),
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      return this.request(`/asset/${id}`, options).then(
        this.handleJSONResponse
      );
    },
  };

  folders = {
    getFoldersByArrayIds: async (ids) => {
      const options = {
        method: 'POST',
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
        body: JSON.stringify({
          ids,
        }),
      };
      return this.request(`/folders/by-array`, options).then(
        this.handleJSONResponse
      );
    },
    deleteFolder: async (id) => {
      const options = {
        method: 'DELETE',
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
      };
      return this.request(`/folder/${id}`, options).then(
        this.handleJSONResponse
      );
    },
    getFoldersByQuery: async (type, query) => {
      const options = {
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
      };
      return this.request(`/folders/${type}/search/${query}`, options).then(
        this.handleJSONResponse
      );
    },
    getFolders: async () => {
      const options = {
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
      };
      return this.request(`/folders`, options).then(this.handleJSONResponse);
    },
    getFolderById: async (parentId) => {
      const options = {
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
      };
      return this.request(`/folder/${parentId}`, options).then(
        this.handleJSONResponse
      );
    },
    getFoldersByParent: async (parentId) => {
      const options = {
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          Pragma: 'no-cache',
        },
      };
      return this.request(`/folder/${parentId}/folders`, options).then(
        this.handleJSONResponse
      );
    },
    saveFolder: async (data) => {
      const id = data._id || 'create';
      delete data._id;
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
        },
        body: JSON.stringify({
          ...data,
        }),
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      return this.request(`/folder/${id}`, options).then(
        this.handleJSONResponse
      );
    },
  };

  boards = {
    search: async (id, query) => {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
        },
        body: JSON.stringify({
          query,
        }),
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      return this.request(`/boards/${id}/search`, options).then(
        this.handleJSONResponse
      );
    },
    cloneBoard: async (boardId, newName) => {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
        },
        body: JSON.stringify({
          name: newName,
        }),
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      return this.request(`/boards/${boardId}/clone`, options).then(
        this.handleJSONResponse
      );
    },
    saveBoardPartial: async (boardId, updates, findQuery) => {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
        },
        body: JSON.stringify({
          updates,
          findQuery,
        }),
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      return this.request(`/boards/${boardId}/save-partial`, options).then(
        this.handleJSONResponse
      );
    },
    saveBoardModulePartial: async (moduleId, updates, findQuery) => {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
        },
        body: JSON.stringify({
          updates,
          findQuery,
        }),
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      return this.request(`/boards/module/${moduleId}/save-partial`, options).then(
        this.handleJSONResponse
      );
    },
    saveBoard: async (data) => {
      const id = data._id || 'create';
      delete data._id;
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
        },
        body: JSON.stringify({
          ...data,
        }),
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      return this.request(`/boards/${id}`, options).then(
        this.handleJSONResponse
      );
    },
    getBoard: async (id, params = {}, parentBoard, body) => {
      const options = {
        method: !!body ? "POST" : 'GET',
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
          ...(parentBoard
            ? { 'pickit-request-context': `board_${parentBoard}` }
            : {}),
        },
        ...(!!body ? { body: JSON.stringify(body) } : {}),
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      return this.request(
        `/board/${id}?${new URLSearchParams(params).toString()}`,
        options
      ).then(this.handleJSONResponse);
    },
    getBoards: async (type, include_assets = false) => {
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
        },
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      return this.request(`/boards/${type || ''}${include_assets ? "?include_assets=1" : ""}`, options).then(
        this.handleJSONResponse
      );
    },
    getBoardsByIds: async (ids) => {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
        },
        body: JSON.stringify({ ids }),
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      return this.request(`/boards/from-array`, options).then(
        this.handleJSONResponse
      );
    },
    deleteBoard: async (id) => {
      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
        },
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      return this.request(`/boards/${id}`, options);
    },
    deleteModule: async (id) => {
      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
        },
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      return this.request(`/boards/module/${id}`, options);
    },
    saveModule: async (data) => {
      const id = data._id || 'create';
      delete data._id;
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
        },
        body: JSON.stringify({
          ...data,
        }),
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      return this.request(`/boards/module/${id}`, options).then(
        this.handleJSONResponse
      );
    },
    connect: async (board) => {
      const socket = new WebSocket(
        `${
          window.pickit.config.MEDIA_URL.includes('https') ? 'wss' : 'ws'
        }://${window.pickit.config.MEDIA_URL.split('//').pop()}/boards/${
          board._id
        }/connect`
      );
      socket.onopen = () => {
        socket.send(
          JSON.stringify({
            auth: this.defaultHeaders.Authorization,
            community: this.communitySlug,
            boardId: board._id,
          })
        );
      };
      return {
        socket,
        sendData: (data) => {
          if (socket.readyState === 1) {
            socket.send(
              JSON.stringify({
                auth: this.defaultHeaders.Authorization,
                community: this.communitySlug,
                ...data,
              })
            );
          }
        },
      };
    },
  };

  textFields = {
    getFields: async () => {
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
        },
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      return this.request(`/user/fields`, options).then(
        this.handleJSONResponse
      );
    },
    saveFields: async (fields) => {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'pickit-community': this.communitySlug,
        },
        body: JSON.stringify(fields),
        skipBaseUrl: true,
        requestUrl: window.pickit.config.MEDIA_URL,
      };
      return this.request(`/user/fields`, options).then(
        this.handleJSONResponse
      );
    },
  };
}

export default Files;
